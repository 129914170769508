<template>
    <label
        :for="props.for"
        class="kd-label block font-medium text-sm text-gray-700"
        :class="{
            required,
        }"
    >
        <span v-if="value">{{ value }}</span>
        <span v-else><slot /></span>
    </label>
</template>

<script setup>
    const props = defineProps({
        value: {
            type: String,
            required: false,
        },
        for: {
            type: String,
            required: false,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
    });
</script>

<style scoped>
    label.required::after {
        content: '*';
        color: red;
        margin-left: 0.25rem;
    }
</style>
