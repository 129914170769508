<template>
    <teleport to="body">
        <Toaster position="top-right" rich-colors close-button />
    </teleport>
</template>

<script setup>
    import { watch } from 'vue';
    import { usePage } from '@inertiajs/vue3';
    import toast from '@/Stores/toast';
    import { Toaster } from 'vue-sonner';

    let disableToastMessage = false;

    window.addEventListener('popstate', function () {
        disableToastMessage = true;
    });

    watch(
        () => usePage().props.toast,
        (toastProps) => {
            if (disableToastMessage === false) {
                if (toastProps.length > 0) {
                    toast.addMultiple(toastProps);
                }

                return;
            }
            disableToastMessage = false;
        },
        { immediate: true }
    );
</script>
