<template>
    <input
        ref="input"
        v-bind="$attrs"
        class="border-gray-300 focus:border-primary placeholder-gray-400 focus:ring focus:ring-light-primary focus:ring-opacity-50 rounded-md shadow-sm"
        :class="[disabled ? 'cursor-not-allowed bg-gray-100' : '']"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
    />
</template>

<script setup>
    import { useTemplateRef } from 'vue';

    const inputRef = useTemplateRef('input');

    defineProps({
        modelValue: [String, Number],
        disabled: {
            type: Boolean,
            default: false,
        },
    });

    defineEmits(['update:modelValue']);

    defineExpose({
        focus: () => {
            inputRef.value?.focus();
        },
    });
</script>
