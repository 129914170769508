import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

export default new Echo({
    pusher: Pusher,
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: 'kd',
    wsHost: import.meta.env.VITE_PUSHER_HOST,
    wsPort: 443,
    forceTLS: true,
    encrypted: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
});
