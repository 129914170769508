import { toast as toastVueSonner } from 'vue-sonner';

export default {
    /**
     * Adds a new toast
     *
     * @param {Object} toast
     * @param {string} toast.message
     * @param {string} toast.type (success | info | error)
     */
    add(toast) {
        this.messageType(toast.message, toast.type);
    },
    success(message) {
        this.messageType(message, 'success');
    },
    info(message) {
        this.messageType(message, 'info');
    },
    error(message) {
        this.messageType(message, 'error');
    },
    messageType(message, type) {
        switch (type) {
            case 'success':
                toastVueSonner.success(message);
                break;
            case 'error':
                toastVueSonner.error(message);
                break;
            case 'info':
                toastVueSonner.info(message);
                break;
            default:
                toastVueSonner(message);
        }
    },
    async addMultiple(toasts) {
        for (const toast of toasts) {
            await sleep(100); // we must wait to avoid vue-sonner stacking bug
            this.messageType(toast['message'], toast['type']);
        }
    },
};

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
